// Generated by Framer (ccd740c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {RBtjX7CWp: {hover: true, pressed: true}};

const cycleOrder = ["RBtjX7CWp", "JATsj6orw"];

const variantClassNames = {JATsj6orw: "framer-v-pkreol", RBtjX7CWp: "framer-v-jjh2np"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Active: "RBtjX7CWp", Disabled: "JATsj6orw"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "RBtjX7CWp", tap: uBmh0hdeN, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "RBtjX7CWp", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap14k0uqk = activeVariantCallback(async (...args) => {
if (uBmh0hdeN) {
const res = await uBmh0hdeN(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-UwSqK", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-jjh2np", className)} data-framer-name={"Active"} data-highlight layoutDependency={layoutDependency} layoutId={"RBtjX7CWp"} onTap={onTap14k0uqk} ref={ref} style={{opacity: 1, ...style}} transition={transition} variants={{"RBtjX7CWp-pressed": {opacity: 0.5}, JATsj6orw: {opacity: 0.5}}} {...addPropertyOverrides({"RBtjX7CWp-hover": {"data-framer-name": undefined}, "RBtjX7CWp-pressed": {"data-framer-name": undefined}, JATsj6orw: {"data-framer-name": "Disabled"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-UwSqK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-UwSqK * { box-sizing: border-box; }", ".framer-UwSqK .framer-dm9vwd { display: block; }", ".framer-UwSqK .framer-jjh2np { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; min-height: 19px; min-width: 55px; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-UwSqK .framer-v-jjh2np .framer-jjh2np { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-UwSqK .framer-jjh2np { gap: 0px; } .framer-UwSqK .framer-jjh2np > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-UwSqK .framer-jjh2np > :first-child { margin-left: 0px; } .framer-UwSqK .framer-jjh2np > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 55
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"JATsj6orw":{"layout":["auto","auto"]},"L6n46wzk_":{"layout":["auto","auto"]},"nVQZHoKz4":{"layout":["auto","auto"]}}}
 * @framerVariables {"uBmh0hdeN":"tap"}
 */
const FramerTEY8VD_jW: React.ComponentType<Props> = withCSS(Component, css, "framer-UwSqK") as typeof Component;
export default FramerTEY8VD_jW;

FramerTEY8VD_jW.displayName = "BreadCrumbItem Copy";

FramerTEY8VD_jW.defaultProps = {height: 19, width: 55};

addPropertyControls(FramerTEY8VD_jW, {variant: {options: ["RBtjX7CWp", "JATsj6orw"], optionTitles: ["Active", "Disabled"], title: "Variant", type: ControlType.Enum}, uBmh0hdeN: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerTEY8VD_jW, [])